<template>
  <div id="app">
    <div class="header-container">
    <header class="header">
      <CategoryNav class="category-nav-fixed" /> <!-- 카테고리 네비게이션 위치 수정 -->

      <a href="/" style="margin-left: 170px;">
        <img alt="Vue logo" class="logo" src="./assets/logo.png">
      </a>
      <div class="search-container">
        <input
            type="text"
            class="search-input"
            placeholder="Search..."
            v-model="searchQuery"
            @keyup.enter="search"
        >
      </div>
      <nav class="nav">
        <router-link to="/dashboard" class="nav-item" style="margin-right: 30px;">Dashboard</router-link>
        <router-link to="/cart" class="nav-item" style="margin-right: 30px;">WishList</router-link>
        <a v-if="isAuthenticated" @click="handleLogout" class="nav-item">Logout</a>
        <router-link v-else to="/login" class="nav-item">Login</router-link>
      </nav>
    </header>
    <router-view/>
  </div>
  </div>

</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CategoryNav from './views/CategoryNav.vue';  // 카테고리 네비게이션 컴포넌트 임포트

export default {
  name: 'App',
  components: {
    CategoryNav,  // 카테고리 네비게이션 컴포넌트 등록
  },
  data() {
    return {
      searchQuery: ''
    };
  },
  computed: {
    ...mapGetters(['isAuthenticated'])
  },
  methods: {
    ...mapActions(['logout']),
    async handleLogout() {
      await this.logout();
      this.$router.push('/login');
    },
    search() {
      this.$router.push({ name: 'SearchResults', query: { q: this.searchQuery } });
    }
  }
}
</script>

<style>
html, body {
  background-color: #f0f4f8;
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 80px; /* Adjust margin-top to accommodate the fixed header */
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 30px 50px; /* Adjust padding to balance the layout */
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  box-sizing: border-box;
  z-index: 1000;
}

.logo {
  width: 220px; /* Adjust logo width to make space for search and nav */
}

.search-container {
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 0 10px; /* Adjust padding for spacing */
}

.search-input {
  width: 100%;
  max-width: 400px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.nav {
  display: flex;
  gap: 10px; /* Adjust gap between nav items */
}

.nav-item {
  text-decoration: none;
  color: #2c3e50;
  font-weight: bold;
  transition: color 0.3s;
}

.nav-item:hover {
  color: #007bff;
}

.category-nav-fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001; /* Ensure it is above other content */
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: center;
    padding: 10px;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 60px;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    background-color: transparent;
    background-color: #F0F4F8;
  }

  .logo-container {
    display: none; /* Hide the logo on mobile */
  }

  .search-container {
    order: 2; /* Ensure search container is below nav items */
    width: 100%;
    padding: 0 10px;
  }

  .search-input {
    max-width: 100%;
    width: 100%;
  }

  .nav {
    display: flex;
    justify-content: space-around;
    width: 100%;
    order: 1;
    padding-top: 30px;
    background-color: #F0F4F8;
  }

  .product-header{
    background-color: #F0F4F8;
  }

  .nav-item {
    padding: 10px;
    font-size: 14px;
  }

  .category-menu {
    width: 100%;
    height: 50px;
    left: 0;
  }

  .logo{
    margin-right: 140px;
    margin-top: 10px;
    background-color: #F0F4F8;
  }

  .home, .cart{
    margin-top: 110px;
  }

  .admin-dashboard, .product-header{
    padding-top: 150px !important;
  }

  #app {
    margin-top: 0; /* Remove top margin on mobile */
    padding-bottom: 70px; /* Ensure space for the fixed footer menu */
  }

  .product-card{
    width: 140px !important;
  }
}

</style>
