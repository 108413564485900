<template>
  <div>
    <nav class="category-nav">
      <div class="category-menu" @mouseover="showCategories" @mouseleave="hideCategories">
        카테고리
        <ul v-if="showCategoryMenu" class="category-list">
          <li v-for="category in categories" :key="category.id" class="category-item" @mouseover="showSubCategories(category)">
            {{ category.ko1 }}
            <ul v-if="category.showSub" class="subcategory-list" @mouseleave="hideSubCategories(category)">
              <li v-for="subCategory in category.subCategories" :key="subCategory.id" @click="selectSubCategory(subCategory)" class="subcategory-card">
                {{ subCategory.ko2 }}
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      categories: [],
      error: null,
      showCategoryMenu: false,
      hideTimeout: null,
    };
  },
  created() {
    this.fetchCategories();
  },
  methods: {
    ...mapActions(['selectCategory']),
    async fetchCategories() {
      try {
        const token = localStorage.getItem('token');
        const headers = token ? { 'Authorization': `Bearer ${token}` } : {};
        const response = await axios.get(`https://k-onnectb2b.store/api/v1/categories?origin=${this.origin}`, { headers });

        // console.log('Fetched categories response:', response);
        if (response.data && Array.isArray(response.data)) {
          const categoryMap = {};
          response.data.forEach(category => {
            if (!categoryMap[category.category_code_1]) {
              categoryMap[category.category_code_1] = {
                ko1: category.ko1,
                subCategories: []
              };
            }
            categoryMap[category.category_code_1].subCategories.push({
              id: category.id,
              ko2: category.ko2,
              category_code_1: category.category_code_1,
              category_code_2: category.category_code_2,
            });
          });

          this.categories = Object.values(categoryMap).map((category, index) => ({
            id: index,
            ...category,
            showSub: false
          }));
        } else {
          console.error('Categories response is not an array:', response.data);
        }
      } catch (err) {
        this.error = 'Failed to fetch categories';
        console.error(err);
      }
    },
    showCategories() {
      clearTimeout(this.hideTimeout);
      this.showCategoryMenu = true;
    },
    hideCategories() {
      this.hideTimeout = setTimeout(() => {
        this.showCategoryMenu = false;
        this.categories.forEach(cat => cat.showSub = false);
      }, 300);
    },
    showSubCategories(category) {
      clearTimeout(this.hideTimeout);
      this.categories.forEach(cat => cat.showSub = false);
      category.showSub = true;
    },
    hideSubCategories(category) {
      this.hideTimeout = setTimeout(() => {
        category.showSub = false;
      }, 300);
    },
    keepSubCategories(category) {
      clearTimeout(this.hideTimeout);
      category.showSub = true;
    },
    selectSubCategory(subCategory) {
      this.selectCategory(subCategory);
      this.$router.push('/');
    }
  },
  computed: {
    ...mapGetters(['userOrigin']),
    origin() {
      return this.userOrigin || 'admin';
    }
  }
};
</script>

<style>
.category-nav {
  display: flex;
  justify-content: center;
  background-color: #f8f9fa;
}

.category-menu {
  left: 50px;
  position: relative;
  cursor: pointer;
  padding: 10px;
  width: 80px;
  height: 80px;
  color: #fff;
  background-color: royalblue;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.category-list {
  width: 120px;
  display: none;
  position: absolute;
  top: 100%;
  left: 50px; /* Left position adjusted */
  background-color: white;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.category-menu:hover .category-list {
  display: block;
}

.category-item {
  margin: 0;
  padding: 10px;
  cursor: pointer;
  color: #007bff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.subcategory-list {
  display: none;
  position: absolute;
  top: 0;
  left: 100%;
  width: 120px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.category-item:hover .subcategory-list,
.subcategory-list:hover {
  display: block;
}

.subcategory-card {
  margin: 0;
  padding: 10px;
  cursor: pointer;
  color: #007bff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.subcategory-card:hover {
  background-color: #f8f9fa;
}
</style>
