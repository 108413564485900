import { createStore } from 'vuex';
import axios from 'axios';

export default createStore({
    state: {
        token: localStorage.getItem('token') || '',
        user: JSON.parse(localStorage.getItem('user')) || null,
        categories: [],
        selectedCategory: '',
        selectedCategoryCode1: '',
        selectedCategoryCode2: '',
        products: [],
        searchResults: [],
    },
    mutations: {
        SET_TOKEN(state, token) {
            state.token = token;
            localStorage.setItem('token', token);
        },
        CLEAR_TOKEN(state) {
            state.token = '';
            localStorage.removeItem('token');
            state.user = null;
            localStorage.removeItem('user');
        },
        SET_USER(state, user) {
            // console.log('Setting user:', user); // 로그 추가
            state.user = user;
            localStorage.setItem('user', JSON.stringify(user));
        },
        SET_CATEGORIES(state, categories) {
            state.categories = categories;
        },
        SET_SELECTED_CATEGORY(state, category) {
            state.selectedCategory = category;
            state.selectedCategoryCode1 = category ? category.category_code_1 : '';
            state.selectedCategoryCode2 = category ? category.category_code_2 : '';
        },
        SET_PRODUCTS(state, products) {
            state.products = products;
        },
        SET_SEARCH_RESULTS(state, results) {
            state.searchResults = results;
        },
        SET_USER_ORIGIN(state, origin) {
            if (state.user) {
                state.user.origin = origin;
                localStorage.setItem('user', JSON.stringify(state.user));
            }
        },
        SET_USER_ID(state, id) {
            if (state.user) {
                state.user.id = id;
                localStorage.setItem('user', JSON.stringify(state.user));
            }
        },
    },
    actions: {
        async login({ commit, dispatch }, credentials) {
            try {
                const params = new URLSearchParams();
                params.append('username', credentials.username);
                params.append('password', credentials.password);

                const response = await axios.post('https://k-onnectb2b.store/api/v1/login', params, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                });

                const token = response.data.access_token;
                commit('SET_TOKEN', token);
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

                await dispatch('fetchUser');
                await dispatch('fetchCategories');

                return true;
            } catch (error) {
                console.error('Login error:', error);
                console.error('Login error response:', error.response);
                return false;
            }
        },
        async fetchUser({ commit, state }) {
            try {
                const response = await axios.get('https://k-onnectb2b.store/api/v1/users/me', {
                    headers: {
                        'Authorization': `Bearer ${state.token}`
                    }
                });
                // console.log('Fetched user data:', response.data); // 로그 추가
                commit('SET_USER', response.data);
                commit('SET_USER_ORIGIN', response.data.origin); // 추가
            } catch (error) {
                console.error('Failed to fetch user:', error);
                commit('CLEAR_TOKEN');
            }
        },
        async fetchCategories({ commit, state }) {
            try {
                const response = await axios.get('https://k-onnectb2b.store/api/v1/categories', {
                    headers: {
                        'Authorization': `Bearer ${state.token}`
                    },
                    params: {
                        origin: state.user ? state.user.origin : ''
                    }
                });
                // console.log('Fetched categories response:', response); // 전체 응답 로그 추가
                // console.log('Fetched categories data:', response.data); // 데이터 부분 로그 추가
                commit('SET_CATEGORIES', response.data);
            } catch (error) {
                console.error('Failed to fetch categories:', error);
                console.error('Fetch categories error response:', error.response);
            }
        },
        async fetchProducts({ commit, state }) {
            try {
                const response = await axios.get('https://k-onnectb2b.store/api/v1/product_pages', {
                    headers: {
                        'Authorization': `Bearer ${state.token}`
                    },
                    params: {
                        category_code_1: state.selectedCategoryCode1,
                        category_code_2: state.selectedCategoryCode2,
                    }
                });
                // console.log('Fetched products:', response.data); // 로그 추가
                commit('SET_PRODUCTS', response.data.products);
            } catch (error) {
                console.error('Failed to fetch products:', error);
                console.error('Fetch products error response:', error.response);
            }
        },
        async searchProducts({ commit, state }, query) {
            try {
                const response = await axios.get('https://k-onnectb2b.store/api/v1/products/search', {
                    headers: {
                        'Authorization': `Bearer ${state.token}`
                    },
                    params: {
                        q: query,
                    }
                });
                // console.log('Fetched search results:', response.data); // 로그 추가
                commit('SET_SEARCH_RESULTS', response.data);
            } catch (error) {
                console.error('Failed to search products:', error);
                console.error('Search products error response:', error.response);
            }
        },
        selectCategory({ commit, dispatch }, category) {
            commit('SET_SELECTED_CATEGORY', category);
            dispatch('fetchProducts');
        },
        logout({ commit }) {
            commit('CLEAR_TOKEN');
            delete axios.defaults.headers.common['Authorization'];
        },
    },
    getters: {
        isAuthenticated: state => !!state.token,
        user: state => state.user,
        isAdmin: state => state.user && state.user.origin === 'admin',
        categories: state => state.categories,
        selectedCategory: state => state.selectedCategory,
        selectedCategoryCode1: state => state.selectedCategoryCode1,
        selectedCategoryCode2: state => state.selectedCategoryCode2,
        products: state => state.products,
        searchResults: state => state.searchResults,
        userOrigin: state => state.user ? state.user.origin : null,
        userId: state => state.user ? state.user.id : null // 추가된 부분
    }
});
