<template>
  <div class="login-form-container">
    <div class="login-form-big">
      <h1>Login</h1>
      <form class="login-form" @submit.prevent="handleLogin">
        <input type="text" v-model="username" placeholder="Username" class="input" required>
        <input type="password" v-model="password" placeholder="Password" class="input" required>
        <button type="submit" class="btn">Login</button>
      </form>
      <p v-if="error" class="error-message">{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      username: '',
      password: '',
      error: false,
      errorMessage: ''
    };
  },
  methods: {
    ...mapActions(['login']),
    async handleLogin() {
      const success = await this.login({
        username: this.username,
        password: this.password
      });

      if (success) {
        this.$router.push('/dashboard');
      } else {
        this.error = true;
        this.errorMessage = 'Login failed. Please try again.';
      }
    }
  }
};
</script>

<style scoped>
.login-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  background-color: #f0f4f8;
}

.login-form-big {
  background-color: #ffffff;
  padding: 40px;
  width: 350px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  justify-content: center;
  align-items: center;
}

h1 {
  margin-bottom: 20px;
  color: #007bff;
}

.login-form {
  display: flex;
  flex-direction: column;
}

.input {
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
}

.btn {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
}
</style>
