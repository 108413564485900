import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import axios from 'axios';

const app = createApp(App);

app.use(store);
app.use(router);

// 앱이 로드될 때 토큰이 있다면 사용자 정보를 가져옵니다.
if (store.state.token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${store.state.token}`;
    store.dispatch('fetchUser');
}

app.mount('#app');
