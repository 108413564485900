<template>
  <div class="home">
    <div v-if="loading && products.length === 0">Loading...</div>
    <div v-if="error">{{ error }}</div>
    <div v-else class="product-list">
      <div v-for="product in products" :key="product.product_code" class="product-card" @click="goToProductDetail(product.product_code)">
        <img :src="product.main_image_path" :alt="product.name" class="product-image">
        <h2>{{ productName(product) }}</h2>
        <div v-if="isAuthenticated">
          <p>B2B price: {{ formatPrice(retailPrice(product)) }} KRW</p>
          <p>Retail price: {{ formatPrice(netPrice(product)) }} KRW</p>
          <p>Brand: {{ product.brand }}</p>
          <p>Product Code: {{ product.product_code }}</p>
          <p v-if="product.is_sold_out">Sold Out</p>
        </div>
      </div>
    </div>
    <button v-if="!loading && !allLoaded" @click="loadMoreProducts" class="load-more-btn">Load More</button>
    <div v-if="loading && products.length > 0" class="loader">Loading more...</div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  name: 'HomePage',
  data() {
    return {
      products: [],
      loading: false,
      error: null,
      page: 1,
      limit: 30,
      allLoaded: false,
      isAuthenticated: false
    };
  },
  computed: {
    ...mapGetters(['selectedCategory', 'selectedCategoryCode1', 'selectedCategoryCode2', 'userOrigin']),
    originSpField() {
      return this.userOrigin === 'admin' ? 'actual_supply_price' : `${this.userOrigin}_sp`;
    },
    originRpField() {
      return this.userOrigin === 'admin' ? 'consumer_price' : `${this.userOrigin}_rp`;
    }
  },
  watch: {
    selectedCategoryCode1(newCategoryCode1) {
      if (newCategoryCode1) {
        this.resetProducts();
        this.fetchProducts();
      }
    },
    selectedCategoryCode2(newCategoryCode2) {
      if (newCategoryCode2) {
        this.resetProducts();
        this.fetchProducts();
      }
    }
  },
  created() {
    this.checkAuthStatus();
  },
  methods: {
    async fetchProducts() {
      if (this.allLoaded || this.loading) return;
      this.loading = true;
      try {
        const token = localStorage.getItem('token');
        const headers = token ? { 'Authorization': `Bearer ${token}` } : {};
        const response = await axios.get('https://k-onnectb2b.store/api/v1/product_pages', {
          params: {
            category_code_1: this.selectedCategoryCode1,
            category_code_2: this.selectedCategoryCode2,
            page: this.page,
            limit: this.limit
          },
          headers,
          responseType: 'json'
        });

        // console.log('Fetched products response:', response);
        if (response.data && response.data.products) {
          if (response.data.products.length < this.limit) {
            this.allLoaded = true;
          }
          this.products = [...this.products, ...response.data.products];
          this.page += 1;
          // console.log('Updated products:', this.products);
        } else {
          console.error('Products response is not as expected:', response.data);
        }
      } catch (err) {
        this.error = 'Failed to load products';
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    async checkAuthStatus() {
      try {
        const token = localStorage.getItem('token');
        const headers = token ? { 'Authorization': `Bearer ${token}` } : {};
        const response = await axios.get('https://k-onnectb2b.store/api/v1/auth/status', {
          headers
        });
        this.isAuthenticated = response.data.isAuthenticated;
        this.$store.commit('SET_USER_ORIGIN', response.data.origin);
        this.fetchProducts();
      } catch (err) {
        console.error('Failed to check authentication status', err);
      }
    },
    loadMoreProducts() {
      this.fetchProducts();
    },
    resetProducts() {
      this.products = [];
      this.page = 1;
      this.allLoaded = false;
    },
    productName(product) {
      if (this.userOrigin === 'kn' || this.userOrigin === 'ka') {
        return product.english_name;
      } else if (this.userOrigin === 'sm') {
        return product.chinese_name;
      } else {
        return product.name;
      }
    },
    retailPrice(product) {
      return this.userOrigin === 'admin' ? product.ko_rp : product[`${this.userOrigin}_rp`];
    },
    netPrice(product) {
      return this.userOrigin === 'admin' ? product.ko_sp : product[`${this.userOrigin}_sp`];
    },
    formatPrice(price) {
      return new Intl.NumberFormat().format(price);
    },
    goToProductDetail(productCode) {
      if (productCode) {
        // console.log('Navigating to product detail with product code:', productCode); // 콘솔에 productCode 출력
        this.$router.push({ name: 'ProductDetail', params: { product_code: productCode } });
      } else {
        console.error("Product code is missing");
      }
    }
  }
};
</script>

<style scoped>
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  padding-top: 20px;
  text-align: center;
}

.product-list {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.product-card {
  border: 1px solid #ccc;
  padding: 16px;
  width: 200px;
}

.product-image {
  width: 100%;
  height: auto;
}

h2 {
  font-size: 18px;
  margin: 0;
}

p {
  font-size: 14px;
}

.loader {
  text-align: center;
  padding: 20px;
  font-size: 14px;
  color: #888;
}

.load-more-btn {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin: 20px 0;
  transition: background-color 0.3s;
}

.load-more-btn:hover {
  background-color: #0056b3;
}
</style>
