<template>
  <div class="search-results">
    <div v-if="loading">Loading...</div>
    <div v-if="error">{{ error }}</div>
    <div v-if="searchResults.length === 0 && !loading">No products found.</div>
    <div v-else class="product-grid">
      <div v-for="product in searchResults" :key="product.id" class="product-card">
        <img :src="product.main_image_path" :alt="product.name" class="product-image">
        <h2>{{ product.name }}</h2>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'SearchResults',
  data() {
    return {
      loading: false,
      error: null,
    };
  },
  computed: {
    ...mapGetters(['searchResults']),
  },
  methods: {
    ...mapActions(['searchProducts']),
    async performSearch(query) {
      this.loading = true;
      try {
        await this.searchProducts(query);
      } catch (err) {
        this.error = 'Failed to fetch search results';
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    '$route.query.q'(newQuery) {
      if (newQuery) {
        this.performSearch(newQuery);
      }
    }
  },
  created() {
    const query = this.$route.query.q;
    if (query) {
      this.performSearch(query);
    }
  }
};
</script>

<style scoped>
.search-results {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  padding-top: 20px;
  text-align: center;
}

.product-grid {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.product-card {
  border: 1px solid #ccc;
  padding: 16px;
  width: 200px;
}

.product-image {
  width: 100%;
  height: auto;
}

h2 {
  font-size: 18px;
  margin: 0;
}

p {
  font-size: 14px;
}

.loader {
  text-align: center;
  padding: 20px;
  font-size: 14px;
  color: #888;
}
</style>
