<template>
  <div>
    <div class="cart">
      <div style="height: 50px;" class="space"></div>
      <div class="product-codes-container">
        <div class="header-1">
          <h2 class="title">PRODUCT CODE LIST</h2>
          <div class="buttons">
            <button class="more-btn" @click="toggleExpand">MORE</button>
            <button class="copy-btn" @click="copyProductCodes">COPY</button>
          </div>
        </div>
        <div class="product-codes">
          <div class="product-code-list" :class="{ 'expanded': expanded }">
            <div class="code-container">
              <div v-for="(code, index) in visibleProductCodes" :key="code" class="product-code">
                "{{ code }}"<span v-if="index < visibleProductCodes.length - 1">,</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="loading" class="loading">Loading...</div>
      <div v-else-if="error" class="error">{{ error }}</div>
      <div v-else class="product-list">
        <div v-for="product in products" :key="product.product_code" class="product-card" @click="goToProductDetail(product.product_code)">
          <img :src="product.main_image_path" :alt="product.name" class="product-image">
          <h3>{{ productName(product) }}</h3>
          <div v-if="isAuthenticated">
            <p>B2B price: {{ formatPrice(retailPrice(product)) }} KRW</p>
            <p>Retail price: {{ formatPrice(netPrice(product)) }} KRW</p>
            <p>Brand: {{ product.brand }}</p>
            <p>Product Code: {{ product.product_code }}</p>
            <p v-if="product.is_sold_out">Sold Out</p>
          </div>
          <button @click.stop="confirmRemoveFromCart(product.cart_item_id)" class="remove-btn">Remove</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      cartItems: [],
      products: [],
      loading: false,
      error: null,
      isAuthenticated: false,
      expanded: false,
    };
  },
  computed: {
    ...mapGetters(['userOrigin', 'userId', 'user']),
    originSpField() {
      return this.userOrigin === 'admin' ? 'actual_supply_price' : `${this.userOrigin}_sp`;
    },
    originRpField() {
      return this.userOrigin === 'admin' ? 'consumer_price' : `${this.userOrigin}_rp`;
    },
    productCodes() {
      return this.products.map(product => product.product_code);
    },
    visibleProductCodes() {
      return this.expanded ? this.productCodes : this.productCodes.slice(0, 10);
    }
  },
  mounted() {
    this.checkAuthStatus();
  },
  methods: {
    async fetchCartItems() {
      if (!this.userId) {
        console.error('User ID is not set.');
        return;
      }
      this.loading = true;
      try {
        const response = await axios.get(`https://k-onnectb2b.store/api/v1/carts/${this.userId}/items`);
        this.cartItems = response.data;
        await this.fetchProducts();
      } catch (error) {
        this.error = "Failed to fetch cart items";
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async fetchProducts() {
      try {
        const productCodes = this.cartItems.map(item => item.product_code.toString());
        const email = this.user.email;
        const origin = this.userOrigin;
        const payload = {
          product_codes: productCodes,
          email: email,
          origin: origin
        };
        // console.log('Payload:', JSON.stringify(payload));  // 요청 데이터 형식 확인

        const response = await axios.post('https://k-onnectb2b.store/api/v1/products/by-codes', payload, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (response && response.data) {
          // console.log('Server response:', response.data);  // 서버 응답 확인

          this.products = response.data.map(product => {
            const cartItem = this.cartItems.find(item => item.product_code.toString() === product.product_code.toString());
            if (cartItem) {
              return { ...product, cart_item_id: cartItem.id, quantity: cartItem.quantity };
            } else {
              console.error('Cart item not found for product code:', product.product_code);
              return { ...product, cart_item_id: null, quantity: 0 };
            }
          });
        } else {
          throw new Error('Invalid response data');
        }
      } catch (error) {
        this.error = "Failed to fetch products";
        console.error('Response error:', error.response ? error.response.data : error.message);  // 오류 메시지 확인
      }
    },
    async confirmRemoveFromCart(cartItemId) {
      if (confirm('Are you sure you want to remove this item?')) {
        this.removeFromCart(cartItemId);
      }
    },
    async removeFromCart(cartItemId) {
      try {
        await axios.delete(`https://k-onnectb2b.store/api/v1/carts/items/${cartItemId}`);
        this.fetchCartItems();
      } catch (error) {
        this.error = "Failed to remove item from cart";
        console.error(error);
      }
    },
    async checkAuthStatus() {
      try {
        const token = localStorage.getItem('token');
        const headers = token ? {'Authorization': `Bearer ${token}`} : {};
        const response = await axios.get('https://k-onnectb2b.store/api/v1/auth/status', { headers });
        this.isAuthenticated = response.data.isAuthenticated;
        this.$store.commit('SET_USER_ORIGIN', response.data.origin);
        this.$store.commit('SET_USER_ID', response.data.user_id);
        this.fetchCartItems();
      } catch (err) {
        console.error('Failed to check authentication status', err);
      }
    },
    productName(product) {
      if (this.userOrigin === 'kn' || this.userOrigin === 'ka') {
        return product.english_name;
      } else if (this.userOrigin === 'sm') {
        return product.chinese_name;
      } else {
        return product.name;
      }
    },
    retailPrice(product) {
      return product[`${this.userOrigin}_rp`] || product.consumer_price;
    },
    netPrice(product) {
      return product[`${this.userOrigin}_sp`] || product.actual_supply_price;
    },
    formatPrice(price) {
      return price ? new Intl.NumberFormat().format(price) : 0;
    },
    copyProductCodes() {
      const codes = this.productCodes.map(code => `"${code}"`).join(', ');
      navigator.clipboard.writeText(codes).then(() => {
        alert('Product codes copied to clipboard!');
      });
    },
    toggleExpand() {
      this.expanded = !this.expanded;
    },
    goToProductDetail(productCode) {
      if (productCode) {
        // console.log('Navigating to product detail with product code:', productCode); // 콘솔에 productCode 출력
        this.$router.push({ name: 'ProductDetail', params: { product_code: productCode } });
      } else {
        console.error("Product code is missing");
      }
    }
  }
};
</script>
<style scoped>
.loading {
  text-align: center;
  padding: 20px;
  font-size: 14px;
  color: #888;
}

.error {
  text-align: center;
  padding: 20px;
  font-size: 14px;
  color: red;
}

.product-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.product-card {
  border: 1px solid #ccc;
  padding: 16px;
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensure elements are spaced evenly */
  cursor: pointer;
}

.product-image {
  width: 100%;
  height: auto;
}

.remove-btn {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: auto; /* Ensure the button is at the bottom */
}

.remove-btn:hover {
  background-color: #0056b3;
}

.product-codes-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  border: 2px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  background-color: #f8f9fa;
  width: 80%;
  margin: 20px auto; /* 중앙 정렬을 위해 */
}

.header-1 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.title {
  margin: 0;
}

.buttons {
  display: flex;
  gap: 10px;
}

.copy-btn, .more-btn {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.copy-btn:hover, .more-btn:hover {
  background-color: #0056b3;
}

.product-codes {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  max-width: 100%;
  position: relative;
  align-items: center;
}

.product-code-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-height: 2.5em;
  overflow: hidden;
  transition: max-height 0.5s;
}

.product-code-list.expanded {
  max-height: none;
}

.code-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  position: relative;
}

.product-code {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
