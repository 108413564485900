<template>
  <div class="home">
    <div v-if="loading && products.length === 0">Loading...</div>
    <div v-if="error">{{ error }}</div>
    <div v-else class="product-list">
      <div v-for="product in products" :key="product.product_code" class="product-card">
        <img :src="product.main_image_path" :alt="product.name" class="product-image">
        <h2>{{ product.name }}</h2>
        <div v-if="isAuthenticated">
          <p>Retail price: {{ netPrice(product) }} KRW</p>
          <p>Net price: {{ retailPrice(product) }} KRW</p>
        </div>
      </div>
    </div>
    <button v-if="!loading && !allLoaded" @click="loadMoreProducts" class="load-more-btn">Load More</button>
    <div v-if="loading && products.length > 0" class="loader">Loading more...</div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'HomePage',
  data() {
    return {
      products: [],
      loading: false,
      error: null,
      origin: '',
      isAuthenticated: false,
      page: 1,
      limit: 30,  // 6 rows with 5 products each
      allLoaded: false,
    };
  },
  computed: {
    originSpField() {
      return this.origin === 'admin' ? 'ko_sp' : `${this.origin}_sp`;
    },
    originRpField() {
      return this.origin === 'admin' ? 'consumer_price' : `${this.origin}_rp`;
    }
  },
  created() {
    this.checkAuthStatus();
  },
  watch: {
    origin(newOrigin) {
      if (newOrigin) {
        this.resetProducts();
        this.fetchProducts();
      }
    }
  },
  methods: {
    async fetchProducts() {
      if (this.allLoaded || this.loading) return;
      this.loading = true;
      try {
        const token = localStorage.getItem('token');
        const headers = token ? {'Authorization': `Bearer ${token}`} : {};
        const response = await axios.get(`/api/v1/product_pages?page=${this.page}&limit=${this.limit}`, {
          headers
        });
        if (response.data.products.length < this.limit) {
          this.allLoaded = true;
        }
        this.products = [...this.products, ...response.data.products];
        this.page += 1;
        // console.log('Updated products:', this.products);
      } catch (err) {
        this.error = 'Failed to load products';
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    async checkAuthStatus() {
      try {
        const token = localStorage.getItem('token');
        const headers = token ? {'Authorization': `Bearer ${token}`} : {};
        const response = await axios.get('/api/v1/auth/status', {
          headers
        });
        this.isAuthenticated = response.data.isAuthenticated;
        this.origin = response.data.user.origin;
        this.fetchProducts();
      } catch (err) {
        console.error('Failed to check authentication status', err);
      }
    },
    loadMoreProducts() {
      this.fetchProducts();
    },
    resetProducts() {
      this.products = [];
      this.page = 1;
      this.allLoaded = false;
    },
    retailPrice(product) {
      return this.origin === 'admin' ? product.ko_sp : product[this.originRpField];
    },
    netPrice(product) {
      return this.origin === 'admin' ? product.ko_rp : product[this.originSpField];
    }
  }
};
</script>

<style scoped>
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  padding-top: 20px;
  text-align: center;
}

.product-list {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.product-card {
  border: 1px solid #ccc;
  padding: 16px;
  width: 200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.product-image {
  width: 100%;
  height: auto;
}

h2 {
  font-size: 18px;
  margin: 0;
}

p {
  font-size: 14px;
}

.loader {
  text-align: center;
  padding: 20px;
  font-size: 14px;
  color: #888;
}

.load-more-btn {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin: 20px 0;
  transition: background-color 0.3s;
}

.load-more-btn:hover {
  background-color: #0056b3;
}
</style>
