<template>
  <div>
    <div v-if="loading" class="loading">Loading...</div>
    <div v-else-if="error" class="error">{{ error }}</div>
    <div v-else class="product-detail">
      <div class="product-header">
        <img :src="product.main_image_path" :alt="productName" class="product-image">
        <div class="product-info">
          <div>
            <h1 class="product-name">{{ productName }}</h1>
            <div class="product-prices">
              <div class="prices" style="border-bottom: 1px solid #007bff ">
                <p class="price-label">KOR MSRP:</p>
                <p class="price-value">{{ formatPrice(product.consumer_price) }} KRW</p>
              </div>
              <div class="prices" style="border-bottom: 1px solid #007bff ">
                <p class="price-label">Lowest Internet Price:</p>
                <p class="price-value">{{ formatPrice(product.lowest_price) }} KRW</p>
              </div>
              <div class="prices" style="border-bottom: 1px solid #007bff ">
                <p class="price-label">B2B Price:</p>
                <p class="price-value">{{ formatPrice(retailPrice(product)) }} KRW</p>
              </div>
              <div class="prices" style="border-bottom: 1px solid #007bff ">
                <p class="price-label">Retail Price:</p>
                <p class="price-value">{{ formatPrice(netPrice(product)) }} KRW</p>
              </div>
              <div class="prices" >
                <p class="price-label">Brand: {{ product.brand }}</p>
                <p class="price-value">Product Code: {{ product.product_code }}</p>
              </div>
            </div>
          </div>
          <button class="add-to-cart-btn" @click="addToCart">Add to WishList</button>
        </div>
      </div>
      <div class="description-images">
        <img v-for="url in imageUrls" :key="url" :src="url" :alt="productName" class="description-image">
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  name: 'ProductDetail',
  data() {
    return {
      product: null,
      loading: true,
      error: null,
      quantity: 1,
      imageUrls: []
    };
  },
  computed: {
    ...mapGetters(['userOrigin', 'userId']),
    productName() {
      if (!this.product) return '';
      if (this.userOrigin === 'kn' || this.userOrigin === 'ka') {
        return this.product.english_name;
      } else if (this.userOrigin === 'sm') {
        return this.product.chinese_name;
      } else {
        return this.product.name;
      }
    },
    productPrice() {
      if (!this.product) return 0;
      return this.userOrigin === 'admin' ? this.product.ko_rp : this.product[`${this.userOrigin}_rp`];
    }
  },
  async created() {
    // console.log('User ID from Vuex:', this.userId); // userId 출력 로그
    const productCode = this.$route.params.product_code;
    // console.log('Fetched product code from route:', productCode); // 콘솔에 productCode 출력
    if (productCode) {
      await this.fetchProduct(productCode);
      this.extractImageUrls(this.product.description_image_path);
    } else {
      this.error = "Product code is missing";
      this.loading = false;
    }
  },
  methods: {
    async fetchProduct(code) {
      this.loading = true;
      try {
        const response = await axios.get(`https://k-onnectb2b.store/api/v1/products/${code}`);
        this.product = response.data;
      } catch (err) {
        this.error = 'Failed to load product details';
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    formatPrice(price) {
      return new Intl.NumberFormat().format(price);
    },
    async addToCart() {
      if (!this.userId) {
        console.error('User ID is not set.');
        return;
      }
      try {
        const token = localStorage.getItem('token');
        const headers = token ? {'Authorization': `Bearer ${token}`} : {};
        // console.log('Adding to cart with payload:',
        // {
        //   product_code: this.product.product_code,
        //   quantity: this.quantity
        // });
        // eslint-disable-next-line no-unused-vars
        const response = await axios.post(`https://k-onnectb2b.store/api/v1/carts/${this.userId}/items`, {
          product_code: this.product.product_code,
          quantity: this.quantity
        }, {headers});
        // console.log(`Added ${this.quantity} of product ${this.product.name} to cart. Response:`, response.data);
      } catch (err) {
        console.error('Failed to add product to cart', err);
        if (err.response) {
          console.error('Response data:', err.response.data);
        }
      }
    },
    retailPrice(product) {
      return this.userOrigin === 'admin' ? product.ko_rp : product[`${this.userOrigin}_rp`];
    },
    netPrice(product) {
      return this.userOrigin === 'admin' ? product.ko_sp : product[`${this.userOrigin}_sp`];
    },
    extractImageUrls(description) {
      const urls = description.match(/https?:\/\/[^\s]+/g);
      this.imageUrls = urls || [];
    }
  }
};
</script>

<style scoped>
.loading {
  text-align: center;
  padding: 20px;
  font-size: 14px;
  color: #888;
}

.error {
  text-align: center;
  padding: 20px;
  font-size: 14px;
  color: red;
}

.product-detail {
  display: flex;
  background-color: white;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.product-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  max-width: 800px;
  padding: 50px;
}

.product-image {
  max-width: 400px;
  width: 100%;
  height: auto;
}

.product-info {
  flex: 1;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 400px; /* Fixed height to ensure the button is at the bottom */
}

.product-name {
  font-size: 24px;
  margin-bottom: 40px;
}

.product-prices {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.prices {
  height: 40px;
  display: flex;
  justify-content: space-between;
}

.price-label {
  width: 50%;
  text-align: left;
}

.price-value {
  width: 50%;
  text-align: right;
}

.add-to-cart-btn {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: auto; /* Ensure the button is at the bottom */
}

.add-to-cart-btn:hover {
  background-color: #0056b3;
}

.description-images {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center; /* Center align images */
  margin-top: 20px;
}

.description-image {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.product-description {
  font-size: 16px;
  color: #333;
  width: 100%;
  max-width: 800px;
  margin-top: 10px;
}

/* 모바일 뷰를 위한 미디어 쿼리 */
@media (max-width: 768px) {
  .product-header {
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .product-image {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .product-info {
    margin-left: 0;
    align-items: center;
    text-align: center;
    height: auto; /* Adjust height for better mobile view */
  }

  .product-prices .prices {
    align-items: center;
  }

  .price-label, .price-value {
    width: auto;
    text-align: center;
  }

  .add-to-cart-btn {
    margin-top: 20px;
    width: 100%;
  }
}
</style>
